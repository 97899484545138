import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>
      Такой страницы пока что не существует...{" "}
      <Link to={"/"}>Попробуйте начать поиск с главной страницы.</Link>
    </p>
  </Layout>
)

export default NotFoundPage
